import { initializeApollo } from "lib/apollo"
import Cookies from "js-cookie"
import { Q_REQ_TOKEN } from "../graphql/query"
import nookies from "nookies"
import * as NextApollo from "lib/apollo/req_token"
import * as UrlPage from "components/page_url"
import { countries } from "../../data/country"
import EmojiFlag from "emoji-flags"
import { MAIN_URL } from "lib"
import axios from "axios"

const ValidateCookies = () => {
  let guestToken = Cookies.get(`${process.env.NEXT_PUBLIC_GUEST_TOKEN}`)
  return { token: guestToken }
}
/**
 *
 * @param {*} opt
   Sample Opt: { mutation: Q_CATALOGS, variables: { id: "1" }, fetchPolicy: "no-cache" }
 * @param {*} token
 * @returns
 */
export const AsyncApollo = async (opt, token) => {
  try {
    let client
    let { query, mutation } = opt
    if (token) client = initializeApollo(token)
    else client = initializeApollo(ValidateCookies().token)
    let fetch
    if (query) fetch = client.query({ ...opt, errorPolicy: "all" })
    else if (mutation) fetch = client.mutate({ ...opt, errorPolicy: "all" })
    else
      return { errors: { message: "Need query, example: query / mutation " } }
    let { data, errors } = await fetch
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
 * @param {*} ctx
 * @param {*} token
 * @param {*} type = enum "guest" / "access_token"
 */
export const SetToken = (ctx, token, type) => {
  if (type === "guest") {
    nookies.set(ctx, process.env.NEXT_PUBLIC_GUEST_TOKEN, token, {
      maxAge: 5 * 24 * 60 * 60,
    })
    return { token, user_status: false }
  }
}

// export const TokenValidation = async (ctx) => {
//   let guestToken = nookies.get(ctx)[process.env.NEXT_PUBLIC_GUEST_TOKEN]
//   if (guestToken) return { token: guestToken, user_status: false }
//   // 0.4166
//   try {
//     let tokenData = await axios.post(MAIN_URL("/api/authentication"), {
//       clientId: process.env.NEXT_PUBLIC_REST_CLIENT_ID,
//       clientSecret: process.env.NEXT_PUBLIC_REST_CLIENT_SECRET,
//     })
//     if (tokenData?.data?.status) {
//       nookies.set(
//         ctx,
//         process.env.NEXT_PUBLIC_GUEST_TOKEN,
//         tokenData.data?.data?.token,
//         {
//           maxAge: 1 * 24 * 60 * 60,
//         }
//       )
//       return { token: tokenData.data?.data?.token }
//     }
//     return { token: "" }
//   } catch (error) {
//     return { errors: error, token: "" }
//   }

//   // let guestToken = nookies.get(ctx)[`${process.env.NEXT_PUBLIC_GUEST_TOKEN}`]
//   // try {
//   //   if (guestToken) return { token: guestToken, user_status: false }
//   //   //prettier-ignore
//   //   let client = NextApollo.initializeApollo();
//   //   let { data, errors } = await client.query({
//   //     query: Q_REQ_TOKEN,
//   //     variables: {
//   //       client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
//   //       client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET,
//   //     },
//   //     context: { headers: { "versioninfo": "1.1.19" } },
//   //     errorPolicy: "all",
//   //     fetchPolicy: "no-cache",
//   //   })
//   //   if (data?.requestAuthToken?.token)
//   //     return { token: data?.requestAuthToken?.token }
//   // } catch (error) {
//   //   return { errors: error }
//   // }
// }

export const EnumAuthCheck = (status) => {
  const enumVar = [
    { id: 1, status: "OTP", url: UrlPage?.otp_link },
    { id: 2, status: "PIN", url: UrlPage?.pin_link },
    { id: 3, status: "CREATE_PIN", url: UrlPage?.created_pin_link },
    { id: 4, status: "ACCOUNT_SUSPENDED", url: "/" },
  ]
  let infoStatus = enumVar.find((el) => el.status === status)
  // Cookies.set(process.env.NEXT_PUBLIC_PROCESS_STATUS, infoStatus.status, {
  //   expires: 1,
  // })
  return infoStatus
}

export const getCountryData = () => {
  countries.forEach((item) => {
    let { code } = item
    item.flag = EmojiFlag.countryCode(code)?.emoji
  })
  return countries
}
