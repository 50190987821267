import nookies from "nookies"
import Cookies from "js-cookie"

export * from "lib/apollo"
export * from "lib/functions"
export * from "lib/graphql/mutation"
export * from "lib/graphql/query"

export const HeaderToken = (ctx) => {
  let token
  if (ctx) token = nookies.get(ctx)[process.env.NEXT_PUBLIC_GUEST_TOKEN]
  else token = Cookies.get(process.env.NEXT_PUBLIC_GUEST_TOKEN)
  return {
    Authorization: `Bearer ${token}`,
    //  Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6ImJEaXhYeDJlVEtEaG5KejVTUjV0MEQxZ2dVek5QR2E3YSIsImlhdCI6MTY2NjYwMzM2NiwiZXhwIjoxNjY2Njg5NzY2fQ.81W5WtwQt5OcwbgcG0pdkNX8OmaAqDmuAY6huk3q03g`,
  }
}

export const MAIN_URL = (url) => {
  let baseUrl = process.env.NEXT_PUBLIC_REST_API
  return `${baseUrl}${url}`
}

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000).toISOString()
}

export function allowOnlyNumbers(e) {
  // check input using regex
  var regex = RegExp(/[0-9]+/g)
  const test_result = regex.test(e.target.value)

  if (test_result) {
    e.target.defaultValue = e.target.value
  } else {
    e.target.value = e.target.defaultValue
  }
}
