import { gql } from "@apollo/client"

//REQUES TOKEN
export const Q_REQ_TOKEN = gql`
  query REQ_TOKEN($client_id: String!, $client_secret: String!) {
    requestAuthToken(clientId: $client_id, clientSecret: $client_secret) {
      token
      tokenType
      expiredDate
    }
  }
`
