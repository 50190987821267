import { gql } from "@apollo/client"

export const M_CHECK_NUMBER = gql`
  mutation CHECK_NUMBER($phoneCode: String!, $phoneNumber: String!) {
    checkPhoneNumber(phoneCode: $phoneCode, phoneNumber: $phoneNumber) {
      status
      token
    }
  }
`

export const M_SEND_OTP = gql`
  mutation SEND_OTP($phoneNumber: String!, $phoneCode: String!) {
    sendOTP(phoneNumber: $phoneNumber, phoneCode: $phoneCode, OTPMethod: SMS) {
      success
    }
  }
`

export const M_VERIFY_OTP = gql`
  mutation VERIFY_OTP(
    $phoneNumber: String!
    $phoneCode: String!
    $otp: String!
  ) {
    verifyOTP(phoneNumber: $phoneNumber, phoneCode: $phoneCode, otp: $otp) {
      success
      token
    }
  }
`

export const M_VERIFY_OTP_FORGOT_PIN = gql`
  mutation VERIFY_UPDATE(
    $phoneNumber: String!
    $phoneCode: String!
    $otp: String!
  ) {
    verifyOTPUpdatePin(
      phoneNumber: $phoneNumber
      phoneCode: $phoneCode
      otp: $otp
    ) {
      success
      token
    }
  }
`

export const M_LOGIN_MEMBER = gql`
  mutation LOGIN_MEMBER12(
    $phoneCode: String!
    $phoneNumber: String!
    $pin: String!
  ) {
    loginMember(phoneCode: $phoneCode, phoneNumber: $phoneNumber, pin: $pin) {
      member {
        memberCode
        address
        city
        gender
        fullname
        phoneNumber
        phoneCode
        email
        nationalityCode
        nationalityName
        referalCode
        dateOfBirth
      }
      token
      tokenType
      tokenExpired
    }
  }
`

// tokenExpired
// tokenType
// member {
//   address
//   city
//   dateOfBirth
//   email
//   fullname
//   gender
//   isBirthDay
//   memberCode
//   nationalityCode
//   nationalityName
//   phoneCode
//   phoneNumber
//   referalCode
//   registerDate
//   status
//   totalPointBalance
//   uuid
// }

export const M_UPDATE_PIN = gql`
  mutation UPDATE_PIN(
    $phoneCode: String!
    $phoneNumber: String!
    $pin: String!
    $token: String!
  ) {
    updatePIN(
      phoneCode: $phoneCode
      phoneNumber: $phoneNumber
      pin: $pin
      token: $token
    ) {
      status
    }
  }
`

export const M_REGISTER_MEMBER = gql`
  mutation REGISTER_MEMBER(
    $address: String
    $city: String
    $dateOfBirth: String!
    $email: String!
    $fullname: String!
    $gender: Gender
    $nationalityCode: String
    $phoneNumber: String!
    $phoneCode: String!
    $referralCode: String
    $token: String!
  ) {
    registerMember(
      phoneNumber: $phoneNumber
      phoneCode: $phoneCode
      fullname: $fullname
      email: $email
      dateOfBirth: $dateOfBirth
      gender: $gender
      address: $address
      city: $city
      nationalityCode: $nationalityCode
      referralCode: $referralCode
      token: $token
    ) {
      status
      memberCode
      address
      city
      gender
      fullname
      phoneNumber
      phoneCode
      email
      nationalityCode
      nationalityName
      referalCode
      token
    }
  }
`

export const M_CREATE_PIN = gql`
  mutation CREATE_PIN(
    $phoneCode: String!
    $phoneNumber: String!
    $pin: String!
    $token: String!
  ) {
    createPIN(
      phoneCode: $phoneCode
      phoneNumber: $phoneNumber
      pin: $pin
      token: $token
    ) {
      member {
        memberCode
        address
        city
        gender
        fullname
        phoneNumber
        phoneCode
        email
        nationalityCode
        nationalityName
        referalCode
        dateOfBirth
      }
      token
      tokenType
      tokenExpired
    }
  }
`

export const M_DELETE_ACCOUNT = gql`
  mutation DELETE_PHONE_NUMBER($phoneCode: String!, $phoneNumber: String!) {
    deletePhoneNumber(phoneCode: $phoneCode, phoneNumber: $phoneNumber) {
      success
    }
  }
`
