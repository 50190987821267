import React from "react"
import "assets/scss/main.scss"
import "react-spring-bottom-sheet/dist/style.css"

import App from "next/app"
import { TokenValidation } from "lib/functions"
import { getCountryData } from "lib"
import Router from "next/router"
import { Provider, useCreateStore } from "lib/store"
import Toast from "components/global/toast"
import TagManager from "react-gtm-module"

//!Loading at top screen
import nProgress from "nprogress"
nProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", (url) => nProgress.start())
Router.events.on("routeChangeComplete", () => nProgress.done())
Router.events.on("routeChangeError", () => nProgress.done())

function MyApp({ Component, pageProps }) {
  //Utils **************************************************************************************//
  const createStore = useCreateStore(pageProps?.initialZustandState)
  let countries = getCountryData()

  //Utils **************************************************************************************//

  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
  }, [])

  return (
    <Provider createStore={createStore}>
      <Toast />
      <Component {...pageProps} countries={countries} />
    </Provider>
  )
}

export default MyApp

// MyApp.getInitialProps = async (appContext) => {
//   let { token, errors } = await TokenValidation(appContext.ctx)
//   let appProps = await App.getInitialProps(appContext)
//   let countries = getCountryData()
//   return { ...appProps, token, countries }
// }
