import { useEffect } from "react"
import { useStore } from "lib/store"
import NoWifiPNG from "assets/img/common/no-wifi.png"

const Toast = () => {
  const { isToast, toastState, toastTime } = useStore((state) => state)
  const closeToast = useStore((state) => state.setToast)

  const handleClickClose = () => {
    closeToast({ isToast: false, toastState: { message: "", status: "" } })
  }

  useEffect(() => {
    if (isToast)
      setTimeout(() => {
        closeToast({
          isToast: false,
          toastState: { message: "", status: "" },
        })
      }, toastTime)
    if (toastState?.status !== "loading") {
    }
  }, [isToast])

  return (
    <div className={`toast-wrapper ${!isToast ? "hide" : ""}`}>
      <div
        className={`toast-state ${!isToast ? "hide" : "show"} ${
          toastState?.status
        }`}
      >
        <div className="close-toast" onClick={handleClickClose}>
          <i className="ail ai-times pointer" />{" "}
        </div>
        {toastState?.message === "no-wifi" ? (
          <div className="d-flex">
            <div className="d-flex align-items-center">
              {/* <img
                className="img-fluid"
                width={"30px"}
                height={"150px"}
                src={NoWifiPNG}
              /> */}
            </div>
            <div className="d-flex align-items-center ml-3">
              You are currently offline
            </div>
          </div>
        ) : (
          <span>{toastState?.message} </span>
        )}
      </div>
    </div>
  )
}

export default Toast
